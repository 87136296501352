import { Injectable } from "@angular/core";
import { Qrcode } from "../qrcode.model";
import { Adapter } from "./adapter";
import { ClickCollectAdapter } from "./click-collect.adapter";
import { OpeningHoursAdapter } from "./opening-hours.adapter";
import { QrcodeThemeAdapter } from "./qrcode-theme.adapter";

@Injectable({
    providedIn: "root",
})
export class QrcodeAdapter implements Adapter<Qrcode> {

    constructor(
        protected qrcodeThemeAdapter: QrcodeThemeAdapter,
        protected openingHoursAdapter: OpeningHoursAdapter,
        protected clickCollectAdapter: ClickCollectAdapter
    ) {}

    adapt(item: any): Qrcode {
        let qrcodeThemes = item.qrcodeThemes ? item.qrcodeThemes.map(subitem => this.qrcodeThemeAdapter.adapt(subitem)) : [];
        let openingHours = item.openingHours ? item.openingHours.map(subitem => this.openingHoursAdapter.adapt(subitem)) : [];
        let clickCollect = item.clickCollect ? this.clickCollectAdapter.adapt(item.clickCollect) : null;

        return new Qrcode(
            item.id,
            item.name,
            item.headline,
            item.licence,
            item.stripeCurrency,
            item.currencyTableValue,
            item.currencyTableMinOrder,
            item.hash,
            item.clickCollectEnable,
            item.logoEnable,
            item.isPromote,
            item.alergenEnable,
            item.nutriscoreEnable,
            item.showPhone,
            item.productDescEnable,
            item.themeIllustrationEnable,
            item.qrcodeNameEnable,
            item.clientNameEnable,
            item.headlineEnable,
            item.currency,
            item.menuType,
            item.activation,
            item.color1,
            item.color2,
            item.color3,
            item.color4,
            item.color5,
            item.color6,
            item.color7,
            item.color8,
            item.theme,
            item.websiteUrl,
            item.reservationUrl,
            item.facebookUrl,
            item.instagramUrl,
            item.youtubeUrl,
            item.twitterUrl,
            item.tripadvisorUrl,
            item.whatsAppUrl,
            item.tikTokUrl,
            item.wifi,
            item.wifiPassword,
            item.customOpeningHours,
            item.hours,
            item.phone,
            item.address,
            item.zipcode,
            item.city,
            item.country,
            item.longitude,
            item.latitude,
            item.familly,
            item.subFamilly,
            item.beginAt,
            item.endAt,
            item.parentId,
            item.currencyTableId,
            item.versionId,
            item.version,
            clickCollect,
            qrcodeThemes,
            openingHours
        );
    }
}