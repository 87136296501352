import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";
import { Allergen } from '../allergen.model';

@Injectable({
    providedIn: "root",
})
export class AllergenAdapter implements Adapter<Allergen> {
    adapt(item: any): Allergen {
        return new Allergen(
            item.id,
            item.name,
            item.iconName
        );
    }
}