import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ProcessOrder } from "src/app/core/models/process-order.model";
import { ProcessOrderAdapter } from "../models/adapter/process-order.adapter";
import { DateService } from "./date.service";

@Injectable({
  providedIn: "root"
})
export class ProcessService {

  private useLocalStorage: boolean = false;

  /** @member {BehaviorSubject.<ProcessOrder>} $process */
  private $process: BehaviorSubject<ProcessOrder>;

  /** @member {BehaviorSubject.<ProcessOrder>} $editProcess */
  private $editProcess: BehaviorSubject<ProcessOrder>;

  /** @member {boolean} $isEditMode */
  public isEditMode: boolean = false;

  /** @member {BehaviorSubject.<Object.<string, *>>} $status */
  private $status: BehaviorSubject<{[key: string]: any}>;
  private processOrderAdapter: ProcessOrderAdapter;

  //constructor
  constructor(protected dateService: DateService) {
    const processOrder = this.useLocalStorage ? localStorage.getItem('accessmenuorder') 
      : sessionStorage.getItem('accessmenuorder');

    this.processOrderAdapter = new ProcessOrderAdapter();
    
    this.$process = new BehaviorSubject<ProcessOrder>(processOrder ? this.processOrderAdapter.adapt(JSON.parse(processOrder)) : null);
    this.$editProcess = new BehaviorSubject<ProcessOrder>(null);

    this.$status = new BehaviorSubject<{[key: string]: any}>({
      isValid: false,
      validate: false,
      selectedMethod: null,
      selectedType: null
    });
  }

  /**
   * Sauvegarder la commande
   * 
   * @param {ProcessOrder} order - commande
   * @param {boolean} edit - edited process
   */
  save(order: ProcessOrder, edit: boolean = false) {
    if(edit) {
      this.$editProcess.next(order);

    } else {
      this.$process.next(order);
      let toStore = JSON.stringify(order);
      this.useLocalStorage ? 
        localStorage.setItem('accessmenuorder', toStore)
        : sessionStorage.setItem('accessmenuorder', toStore)
    }
  }

  /**
   * Supprime la commande
   * 
   * @param {boolean} edit - edited process
   */
  removeProcessOrder(edit: boolean = false) {
    if(edit) {
      this.$editProcess.next(null);

    } else {
      this.$process.next(null);
      this.useLocalStorage ? 
        localStorage.removeItem('accessmenuorder') : sessionStorage.removeItem('accessmenuorder');
    }
  }

  /**
   * Récupère la commande (pouvant être souscrit)
   * 
   * @returns {Observable.<ProcessOrder>} - Obervable de la commande
   */
  getProcessOrder(): Observable<ProcessOrder> {
    return this.$process.asObservable()
  }

  /**
   * Récupère la commande edited (pouvant être souscrit)
   * 
   * @returns {Observable.<ProcessOrder>} - Obervable de la commande
   */
  getEditedProcessOrder(): Observable<ProcessOrder> {
    return this.$editProcess.asObservable()
  }

  /**
   * Récupère le status (pouvant être souscrit) ou le change selon la valeur du paramètre
   * 
   * @param {Object.<Object.<string, *>} value - status
   * 
   * @returns {Observable.<Object.<string, *>>} - Obervable du status
   */
  status(value?: {[key: string]: any}): Observable<{[key: string]: any}> {
    if(!(typeof(value) === 'undefined' || value === null)) {
      this.$status.next(value);
    }
    return this.$status.asObservable();
  }

  /**
   * Initialise le status
   */
  initStatus(): void {
    this.$status.next({
      isValid: false,
      validate: false,
      selectedMethod: null,
      selectedType: null
    })
  }
}