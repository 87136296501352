import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClickCollectAdapter } from '../../models/adapter/click-collect.adapter';
import { ClickCollect } from '../../models/click-collect.model';
import { ApiUrl } from './api.url';
import { CollectionPoint } from '../../models/collection-point.model';
import { LoaderService } from '../loader.service';

@Injectable({
    providedIn: 'root'
})
export class ClickCollectService {

    constructor(
        protected http: HttpClient,
        protected loaderService: LoaderService,
        protected clickCollectAdapter: ClickCollectAdapter
    ) {}

    public getClickCollect(hash: string): Observable<ClickCollect> {
      return this.http.get(`${environment.baseUrl}${ApiUrl.GET_CLICK_COLLECT_INFORMATION}`.replace(':hash', hash)).pipe(
        map((response: any) => {
            return this.clickCollectAdapter.adapt(response);
        }),
      );
    }

    public canDeliver(hash: string, time: number, later: boolean, date: Date): Observable<any> {
      return later
      ? this.getDeliveryTimeValuesFrom(hash, time, date).pipe(map(e => !!e))
      : this.getDeliveryTimeValuesAt(hash, time, date).pipe(map(e => !!e))
    }

    public getDeliveryTimeValuesAt(hash: string, time: number, date: Date): Observable<any> {
      const rid = `${Math.random()}`;
      this.loaderService.add(rid);

      return this.http.get(`${environment.baseUrl}${ApiUrl.GET_DELIVERY_TIMEVALUES_AT}`
      .replace(':hash', hash)
      .replace(':time', (time * 60).toString())
      .replace(':date', date.toISOString())).pipe(
        finalize(() => this.loaderService.remove(rid))
      )
    }

    public getDeliveryTimeValuesFrom(hash: string, time: number, date: Date): Observable<any> {
      const rid = `${Math.random()}`;
      this.loaderService.add(rid);

      return this.http.get(`${environment.baseUrl}${ApiUrl.GET_DELIVERY_TIMEVALUES_FROM}`
      .replace(':hash', hash)
      .replace(':time', (time * 60).toString())
      .replace(':date', date.toISOString())).pipe(finalize(() => this.loaderService.remove(rid)))
    }

    public getCollectionPointsAt(hash: string, time: number, date: Date): Observable<CollectionPoint[]> {
      const rid = `${Math.random()}`;
      this.loaderService.add(rid);

      return this.http.get<CollectionPoint[]>(`${environment.baseUrl}${ApiUrl.GET_COLLECTION_POINTS_AT}`
        .replace(':hash', hash)
        .replace(':time', (time * 60).toString())
        .replace(':date', date.toISOString())).pipe(finalize(() => this.loaderService.remove(rid)))
    }

    public getCollectionPoints(hash: string, time: number, date: Date): Observable<CollectionPoint[]> {
      const rid = `${Math.random()}`;
      this.loaderService.add(rid);

      return this.http.get<CollectionPoint[]>(`${environment.baseUrl}${ApiUrl.GET_COLLECTION_POINTS}`
        .replace(':hash', hash)
        .replace(':time', (time * 60).toString())
        .replace(':date', date.toISOString())).pipe(finalize(() => this.loaderService.remove(rid)))
    }

    public getCollectionPointTimeValuesAt(hash: string, time: number, date: Date): Observable<any> {
      const rid = `${Math.random()}`;
      this.loaderService.add(rid);

      return this.http.get(`${environment.baseUrl}${ApiUrl.GET_CP_TIMEVALUES_AT}`
      .replace(':hash', hash)
      .replace(':time', (time * 60).toString())
      .replace(':date', date.toISOString())).pipe(finalize(() => this.loaderService.remove(rid)))
    }

    public getCollectionPointTimeValuesFrom(hash: string, time: number, date: Date): Observable<any> {
      const rid = `${Math.random()}`;
      this.loaderService.add(rid);

      return this.http.get(`${environment.baseUrl}${ApiUrl.GET_CP_TIMEVALUES_FROM}`
      .replace(':hash', hash)
      .replace(':time', (time * 60).toString())
      .replace(':date', date.toISOString())).pipe(finalize(() => this.loaderService.remove(rid)))
    }
}