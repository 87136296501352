import { Injectable } from "@angular/core";
import { OpeningHours } from "../opening-hours.model";
import { TimeSlot } from "../time-slot.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class OpeningHoursAdapter implements Adapter<OpeningHours> {

    adapt(item: any): OpeningHours {
        let timeSlots = item.timeSlots.map(subitem => this.adaptTimeSlot(subitem));

        return new OpeningHours(
            item.id,
            item.title,
            item.description,
            item.days,
            item.full24h,
            item.type,
            item.icon,
            item.close,
            item.delivery,
            item.clickGo,
            item.showOption,
            item.showAt ? new Date(item.showAt) : null,
            item.activeAt ? new Date(item.activeAt) : null,
            item.beginAt ? new Date(item.beginAt) : null,
            item.endAt ? new Date(item.endAt) : null,
            timeSlots
        );
    }

    adaptTimeSlot(item: any): TimeSlot {
        return new TimeSlot(
            item.id,
            item.beginAt,
            item.endAt
        );
    }
}