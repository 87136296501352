export enum TableRuleActivation {
    active = "active",
    inactive = "inactive",
    time = "time",
}

export class TableRule {
    
    public id: number;
    public name: string; 
    public description: string;
    public values: string; 
    public beginValue: number; 
    public endValue: number; 
    public valueType: string; 
    public position: number;
    public isScheduled: boolean;
    public scheduling: string;
    public softException?: boolean;

    constructor(
        id: number,
        name: string, 
        description: string, 
        values: string, 
        beginValue: number, 
        endValue: number, 
        valueType: string, 
        position: number,
        softException?: boolean

    ) {

        this.id = id;
        this.name = name; 
        this.description = description; 
        this.values = values; 
        this.beginValue = beginValue; 
        this.endValue = endValue; 
        this.valueType = valueType; 
        this.position = position;
        this.softException = softException;

    }
}