import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiUrl } from './api.url';
import { ProductAdapter } from '../../models/adapter/product.adapter';
import { Product } from '../../models/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    navigate(arg0: string, version: any, arg2: string, hash: string, from: any) {
      throw new Error('Method not implemented.');
    }

    constructor(
        protected http: HttpClient,
        protected productAdapter: ProductAdapter,
    ) {}

    public getSuggestions(hash: string, cc: boolean = false, date: string = null): Observable<Product[]> {
        const uri = date 
        ? `${environment.baseUrl}${ApiUrl.GET_SUGGESTIONS_BY_DATE}`.replace(':date', date) 
        :  `${environment.baseUrl}${ApiUrl.GET_SUGGESTIONS}`;

        return this.http.get(uri.replace(':cc', cc ? '1' : '0').replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.productAdapter.adapt(item));
            }),
        );
    }

    public has18YearOld(hash: string, ids: number[]): Observable<boolean> {
        return this.http.post<boolean>(`${environment.baseUrl}${ApiUrl.HAS18_PRODUCTS}`.replace(':hash', hash), {ids})
    }
}

