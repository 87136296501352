
import { Injectable } from "@angular/core";
import { Adapter } from "./adapter";
import { ClickCollect } from '../click-collect.model';

@Injectable({
    providedIn: "root",
})
export class ClickCollectAdapter implements Adapter<ClickCollect> {
    adapt(item: any): ClickCollect {
       
        return new ClickCollect(
            item.id,
            item.delivery,
            item.takeAway,
            item.payment,
            item.paymentInfo,
            item.counterPayment,
            item.onTableOrder,
            item.manufacturingTime,
            item.minOrder,
            item.scheduling,
            item.qrcodeMenuId,
            item.reservations,
            item.collectionPoints
        );
    }
}
