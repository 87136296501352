
export class Version {

    id: number;
    version: string;
    name: string;
    description: string;
    
    constructor(
        id: number,
        version: string,
        name: string,
        description: string
    ) {

        this.id = id;
        this.version = version;
        this.name = name;
        this.description = description;
    }
}