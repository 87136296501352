import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiUrl } from './api.url';
import { OrderRequest } from '../../models/requests/order.request';
import { Order } from '../../models/order.model';
import { OrderAdapter } from '../../models/adapter/order.adapter';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    protected useLocalStorage: boolean = false;

    constructor(
        protected http: HttpClient,
        protected orderAdapter:  OrderAdapter
    ) {}
    
    public save(hash: string, order: OrderRequest): Observable<boolean> {

      return this.http.put(`${environment.baseUrl}${ApiUrl.SAVE_CLICK_COLLECT_ORDER}`.replace(':hash', hash), order).pipe(
        map((response: any) => {
            return true;
        }),
      );
    }


    public saveWthCustomer(hash: string, order: OrderRequest): Observable<boolean> {

      return this.http.put(`${environment.baseUrl}${ApiUrl.SAVE_CLICK_COLLECT_ORDER_NO_CUSTOMER}`.replace(':hash', hash), order).pipe(
        map((response: any) => {
            return true;
        }),
      );
    }

    public getOrders(customerId: number): Observable<any> {
      
      return this.http.get(`${environment.baseUrl}${ApiUrl.CUSTOMER_ORDERS}`.replace(':id', customerId.toString())).pipe(
        map((response: any) => {
           return response.filter(order => order && order.details);
        }),
      );
    }

    public saveOrderToStorage(order: Order) {
      let toStore = JSON.stringify(order)
      this.useLocalStorage ? localStorage.setItem('accessmenucartsetup', toStore) 
      : sessionStorage.setItem('accessmenucartsetup', toStore);
    }

    public getFromStorage(): Order {
      const order = this.useLocalStorage ? localStorage.getItem('accessmenucartsetup') : sessionStorage.getItem('accessmenucartsetup');
      return order ? this.orderAdapter.adapt(order) : null;
    }
}
