import { Qrcode } from "src/app/core/models/qrcode.model";
import { Customer } from "./customer.model";

export enum OrderStatus {
    open = 'open',
    ongoing = 'ongoing',
    delivery = 'delivery',
    closed = 'closed',
    dismiss = 'dismiss',
}

export class Order {
    
    public id: number;
    public customerId: number;
    public clientId: number;
    public qrcodeMenuId: number;
    public status: OrderStatus;
    public order: string;
    public comment?: string;
    public delivery?: string;
    public deliveryCosts: string;
    public deliveryDetails: string;
    public orderType: string;
    public collectionPoint: number;
    public total: number;
    public tablenbr?: string;
    public createdAt: Date;
    public updatedAt?: Date;
    public customer: Customer;
    public qrcodeMenu: Qrcode;
    public details: any;
    public items: any[];
    public orderAt: string;

    constructor(
        id: number,
        customerId: number,
        clientId: number,
        qrcodeMenuId: number,
        status: OrderStatus,
        order: string,
        comment: string,
        delivery: string,
        deliveryCosts: string,
        deliveryDetails: string,
        orderType: string,
        collectionPoint: number,
        total: number,
        tablenbr: string,
        createdAt: Date,
        updatedAt: Date,
        customer: Customer,
        qrcodeMenu: Qrcode,
        details: any,
        items: any[],
        orderAt: string
    ) {
        this.id = id;
        this.customerId = customerId;
        this.clientId = clientId;
        this.qrcodeMenuId = qrcodeMenuId;
        this.status = status;
        this.order = order;
        this.comment = comment;
        this.delivery = delivery;
        this.deliveryCosts = deliveryCosts;
        this.deliveryDetails = deliveryDetails;
        this.orderType = orderType;
        this.collectionPoint = collectionPoint;
        this.total = total;
        this.tablenbr = tablenbr;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.customer = customer;
        this.qrcodeMenu = qrcodeMenu;
        this.details = details;
        this.items = items;
        this.orderAt = orderAt;

    }

    
}