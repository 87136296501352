import { Customer } from "./customer.model";

export class CustomerAddress {

    id: number;
    name: string;
    address: string;
    zipcode: string;
    city: string;
    country: string;
    longitude: string;
    latitude: string;
    favorite: boolean;
    customer: Customer;

    constructor(
        id: number,
        name: string,
        address: string,
        zipcode: string,
        city: string,
        country: string,
        longitude: string,
        latitude: string,
        favorite: boolean,
        customer: Customer) {

        this.id = id
        this.name = name
        this.address = address
        this.zipcode = zipcode
        this.city = city
        this.country = country
        this.longitude = longitude
        this.latitude = latitude
        this.favorite = favorite
        this.customer = customer
        
    }
}


