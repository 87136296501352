import { Qrcode } from "src/app/core/models/qrcode.model";
import { CollectionPoint } from "./collection-point.model";
import { CustomerAddress } from "./customer-address.model";
import { DeliveryZone } from "./delivery-zone.model";

export enum ProcessType {
    clickgo = "clickgo",
    table = "table",
    delivery = "delivery"
}

export enum ProcessMethod {
    now = "now",
    organize = "organize", 
}

export class ProcessOrder {
    type: ProcessType;
    method: ProcessMethod;
    address: CustomerAddress;
    comment: string;
    tablenbr: string;
    deliveryZone: DeliveryZone;
    collectionPoint: CollectionPoint;
    orderAt?: string;
    hash: string;

    constructor(
        type: ProcessType, 
        method: ProcessMethod, 
        address: CustomerAddress, 
        comment: string,
        tablenbr: string,
        deliveryZone: DeliveryZone,
        collectionPoint: any, 
        orderAt: string, 
        hash: string
    ) {
        this.type = type;
        this.method = method;
        this.address = address;
        this.comment = comment;
        this.tablenbr = tablenbr;
        this.deliveryZone = deliveryZone;
        this.collectionPoint = collectionPoint;
        this.orderAt = orderAt;
        this.hash = hash;
    }
}