export class ProductGroupsOptions {

    id: number;
    position: number;
    parentId: number;
    name: string;
    isDefault: number;
    price: number;

    constructor(id: number, position: number, parentId: number, name: string, isDefault: number, price: number = 0) {
        this.id = id;
        this.parentId = parentId;
        this.position = position;
        this.name = name;
        this.isDefault = isDefault;
        this.price = price;
    }
}
