import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiUrl } from './api.url';
import { VersionAdapter } from '../../models/adapter/version.adapter';
import { Version } from '../../models/version.model';

@Injectable({
    providedIn: 'root'
})
export class VersionService {

    private $version: BehaviorSubject<string>;

    constructor(
        protected http: HttpClient,
        protected versionAdapter: VersionAdapter
    ) {
        this.$version = new BehaviorSubject<string>('v2');
    }

    setVersion(version: string) {
        this.$version.next(version);
    }
    
    getVersion(): string {
        return this.$version.value;
    }

    getAsyncVersion(): Observable<string> {
        return this.$version.asObservable();
    }

    get(hash: string): Observable<Version> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_VERSION}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return this.versionAdapter.adapt(response);
            }),
        );
    }

    get3(hash: string): Observable<Version> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_VERSION}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return this.versionAdapter.adapt(response);
            }),
        );
    }

    get2(hash: string): Observable<Version> {

        const v = new Version(null, 'v2', 'v2', '');

        const observable = new Observable<Version>(subscriber => {
            subscriber.next(v);
            throw throwError('response');  
            
        });

        return observable;
    }
}
