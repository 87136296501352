import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TableRuleAdapter } from '../../models/adapter/table-rule.adapter';
import { TableRule } from '../../models/table-rule.model';
import { ApiUrl } from './api.url';

@Injectable({
    providedIn: 'root'
})
export class TableRuleService {

    constructor(
        protected http: HttpClient,
        protected tableRuleAdapter: TableRuleAdapter
    ) {}

    public getTableRules(hash: string): Observable<TableRule[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_TABLE_INCLUDES}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.tableRuleAdapter.adapt(item));
            }),
        );
    }

    public getExceptions(hash: string): Observable<TableRule[]> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.GET_TABLE_EXCEPTIONS}`.replace(':hash', hash)).pipe(
            map((response: any) => {
                return response.map(item => this.tableRuleAdapter.adapt(item));
            }),
        );
    }

}
