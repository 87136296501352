import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, RouterOutlet, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, delay } from 'rxjs/operators';
import { loadCldr } from "@syncfusion/ej2-base";
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Subscription } from 'rxjs';
import { OverlayService, OverlayState } from './core/services/overlay.service';
import { AppStateService } from './core/services/app-state.service';
import { VersionService } from './core/services/api/version.service';
import { LoaderService } from './core/services/loader.service';
import families from './families.json';


declare var require: any
declare var jQuery: any

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	title = 'accessmenuvisit';
	protected subscriptions: Subscription[] = [];
	public version: string;
	public loading: boolean = true;

	@ViewChild('main') main: ElementRef;
	
	constructor(
		private el: ElementRef,
		protected translate: TranslateService,
		protected domSanitizer: DomSanitizer,
		protected matIconRegistry: MatIconRegistry,
		protected router: Router,
		protected appStateService: AppStateService,
		protected overlayService: OverlayService,
		protected versionService: VersionService,
		protected loaderService: LoaderService,
		protected viewportScroller: ViewportScroller) {

		gsap.registerPlugin(ScrollToPlugin);

		translate.setDefaultLang('fr-FR');
		translate.use(navigator.language);
	
		// Register icons
		this.matIconRegistry.addSvgIcon('access-delete', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg'));
		this.matIconRegistry.addSvgIcon('access-left-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/left-arrow.svg'));
		this.matIconRegistry.addSvgIcon('access-right-arrow', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/right-arrow.svg'));
		this.matIconRegistry.addSvgIcon('access-search', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg'));
		this.matIconRegistry.addSvgIcon('access-fait-maison', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fait-maison.svg'));
		this.matIconRegistry.addSvgIcon('access-more', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/more.svg'));
		this.matIconRegistry.addSvgIcon('access-close', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
		this.matIconRegistry.addSvgIcon('access-close-v1', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/closev1.svg'));
		this.matIconRegistry.addSvgIcon('access-facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg'));
		this.matIconRegistry.addSvgIcon('access-twitter', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/twitter.svg'));
		this.matIconRegistry.addSvgIcon('access-youtube', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/youtube.svg'));
		this.matIconRegistry.addSvgIcon('access-instagram', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg'));
		this.matIconRegistry.addSvgIcon('access-tripadvisor', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tripadvisor.svg'));
		this.matIconRegistry.addSvgIcon('access-whatsapp', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/whatsapp.svg'));
		this.matIconRegistry.addSvgIcon('access-reservation', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/reservation.svg'));
		this.matIconRegistry.addSvgIcon('access-venir', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/venir.svg'));
		this.matIconRegistry.addSvgIcon('access-pin', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/access-pin.svg'));
		this.matIconRegistry.addSvgIcon('access-tiktok', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tiktok.svg'));

		this.matIconRegistry.addSvgIcon('access-telephone', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/telephone.svg'));
		this.matIconRegistry.addSvgIcon('access-click-collect', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/click-collect.svg'));
		this.matIconRegistry.addSvgIcon('access-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/access-menu.svg'));
		this.matIconRegistry.addSvgIcon('access-arrow-top', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-top.svg'));
		this.matIconRegistry.addSvgIcon('access-www', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/www.svg'));
    	this.matIconRegistry.addSvgIcon('access-cart', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cart.svg'));
		this.matIconRegistry.addSvgIcon('nutriscrore-a', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/nutriscore-a.svg'));
		this.matIconRegistry.addSvgIcon('nutriscrore-b', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/nutriscore-b.svg'));
		this.matIconRegistry.addSvgIcon('nutriscrore-c', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/nutriscore-c.svg'));
		this.matIconRegistry.addSvgIcon('nutriscrore-d', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/nutriscore-d.svg'));
		this.matIconRegistry.addSvgIcon('nutriscrore-e', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/nutriscore-e.svg'));
		this.matIconRegistry.addSvgIcon('allergen-arachide', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-arachide.svg'));
		this.matIconRegistry.addSvgIcon('allergen-celeris', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-celeris.svg'));
		this.matIconRegistry.addSvgIcon('allergen-coque', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-coque.svg'));
		this.matIconRegistry.addSvgIcon('allergen-crustace', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-crustace.svg'));
		this.matIconRegistry.addSvgIcon('allergen-gluten', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-gluten.svg'));
		this.matIconRegistry.addSvgIcon('allergen-lait', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-lait.svg'));
		this.matIconRegistry.addSvgIcon('allergen-lupin', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-lupin.svg'));
		this.matIconRegistry.addSvgIcon('allergen-mollusque', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-mollusque.svg'));
		this.matIconRegistry.addSvgIcon('allergen-moutarde', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-moutarde.svg'));
		this.matIconRegistry.addSvgIcon('allergen-oeuf', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-oeuf.svg'));
		this.matIconRegistry.addSvgIcon('allergen-poisson', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-poisson.svg'));
		this.matIconRegistry.addSvgIcon('allergen-sesame', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-sesame.svg'));
		this.matIconRegistry.addSvgIcon('allergen-soja', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-soja.svg'));
		this.matIconRegistry.addSvgIcon('allergen-sulfite', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/allergen-sulfite.svg'));
		this.matIconRegistry.addSvgIcon('order', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/order.svg'));
		this.matIconRegistry.addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'));
		this.matIconRegistry.addSvgIcon('logout', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logout.svg'));
		this.matIconRegistry.addSvgIcon('cart-empty', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cart-empty.svg'));
		this.matIconRegistry.addSvgIcon('shipped', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shipped.svg'));
		this.matIconRegistry.addSvgIcon('dismiss', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dismiss.svg'));
		this.matIconRegistry.addSvgIcon('inbox', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/inbox_new.svg'));
		this.matIconRegistry.addSvgIcon('box', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/box.svg'));
		this.matIconRegistry.addSvgIcon('check', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg'));
		this.matIconRegistry.addSvgIcon('logo-click-go-vertical', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/click-collect-new-vertical.svg'));
		this.matIconRegistry.addSvgIcon('logo-click-go-horizontal', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/click-collect-new-horizontal.svg'));
		this.matIconRegistry.addSvgIcon('logo-access-menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/Logo-Access-Menu-white.svg'));
		this.matIconRegistry.addSvgIcon('icon-edit', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit_icon.svg'));
		this.matIconRegistry.addSvgIcon('icon-pin', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pin_icon.svg'));
		this.matIconRegistry.addSvgIcon('chevron-right', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/chevron-right.svg'));
		this.matIconRegistry.addSvgIcon('logo-google', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo-google.svg'));
		this.matIconRegistry.addSvgIcon('icon-vegan', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vegan.svg'));
		this.matIconRegistry.addSvgIcon('icon-vege', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vege.svg'));
		this.matIconRegistry.addSvgIcon('icon-bio', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bio.svg'));
		this.matIconRegistry.addSvgIcon('merci', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/merci.svg'));
		this.matIconRegistry.addSvgIcon('shop-cart', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shopping-cart.svg'));
		this.matIconRegistry.addSvgIcon('shop-cart2', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/shopping-cart2.svg'));

		this.matIconRegistry.addSvgIcon('access-appeler', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/appeler.svg'));
		this.matIconRegistry.addSvgIcon('access-infos', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/infos.svg'));
		this.matIconRegistry.addSvgIcon('access-qrcodes', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/multiqr.svg'));
		this.matIconRegistry.addSvgIcon('access-shop-cart', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/panier.svg'));
		this.matIconRegistry.addSvgIcon('access-pin', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pin.svg'));
		this.matIconRegistry.addSvgIcon('access-profil', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/profil.svg'));
		this.matIconRegistry.addSvgIcon('access-reserver', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/reserver.svg'));
		this.matIconRegistry.addSvgIcon('panier-ok', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/panier-ok.svg'));
		this.matIconRegistry.addSvgIcon('panier-count', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/panier-count.svg'));

		loadCldr(
			require("cldr-data/main/fr/numbers.json"),
			require("cldr-data/main/fr/ca-gregorian.json"),
			require("cldr-data/supplemental/numberingSystems.json"),
			require("cldr-data/main/fr/timeZoneNames.json"),
			require('cldr-data/supplemental/weekData.json') // To load the culture based first day of week
		);

		let keys = Object.keys(families.icons);

		keys.forEach(key => {
			families.icons[key].forEach(icon => {
				this.matIconRegistry.addSvgIcon(`family-${icon.replace('.svg', '')}`, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/famillies/${icon}`));
			});
		});

		// Retrieve scroll position on back action
		this.router.events.pipe(
			filter(e => e instanceof Scroll)
			
		).subscribe((e: Scroll) => {

			if (e.position) {
				// backward navigation
				setTimeout(() => { this.viewportScroller.scrollToPosition([0, 0]); }, 100);

			} else if (e.anchor) {
				// anchor navigation
				setTimeout(() => { this.viewportScroller.scrollToAnchor(e.anchor); }, 500);
			} else {
				// forward navigation
				setTimeout(() => { this.viewportScroller.scrollToPosition([0, 0]); }, 500);
			}
		});
	}
	
	ngOnInit(): void {
		this.subscriptions.push(this.loaderService.getLoading().pipe(delay(0)).subscribe(
			loading => this.loading = loading
		));
		
		this.subscriptions.push(this.appStateService.getScrollTo().subscribe(e => {
			if(!(e === 0 || this.appStateService.isScrolling)) {
				this.appStateService.isScrolling = true;
				setTimeout(() => {
					gsap.to(window, {duration: 1.2,  ease: "Sine.inOut", scrollTo: document.body.scrollHeight})

					this.appStateService.setScrollTo(0)
					this.appStateService.isScrolling = false;

				}, 320)
			}
		}))

		this.subscriptions.push(this.versionService.getAsyncVersion().subscribe(version => this.version = version))

		this.subscriptions.push(
			this.overlayService.getState().subscribe((state: OverlayState) => document.body.style.overflow = state === OverlayState.open ? 'hidden' : 'auto')
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => {
			if(sub) sub.unsubscribe()
		})
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
	}
}
