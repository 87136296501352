import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { CustomerAdapter } from '../models/adapter/customer.adapter';
import { environment } from 'src/environments/environment';
import { ApiUrl } from './api/api.url';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    protected $decodedToken: BehaviorSubject<any>;
    protected $hasCCFeatures: BehaviorSubject<boolean>;

    constructor(
        protected http: HttpClient,
        private customerAdapter: CustomerAdapter
    ) {
        this.$decodedToken = new BehaviorSubject<any>(null);
        this.$hasCCFeatures = new BehaviorSubject<boolean>(this.isLoggedIn())
    }

    public getConnectedBy(): string {
        if(!localStorage.getItem('connectedBy')) return null;
        return localStorage.getItem('connectedBy');
    }

    public getCustomerId(): number {
        if(!localStorage.getItem('customer')) return null;
        return parseInt(localStorage.getItem('customer'));
    }

    public getCustomerEmail(): string {
        const token = this.$decodedToken.value;
        if(!(token && token.email)) return '';
        return token.email;
    }

    public getCustomerName() {
        const token = this.$decodedToken.value;
        if(!(token && token.firstname)) return '';
        return token.firstname;
    }

    public getLastname() {
        const token = this.$decodedToken.value;
        if(!(token && token.lastname)) return '';
        return token.lastname;
    }

    public getFullName() {
        const token = this.$decodedToken.value;
        if(!(token && token.firstname  && token.lastname)) return '';
        return `${token.firstname} ${token.lastname}`;
    }

    public getVerifCode(): string {
        const token = this.$decodedToken.value;
        if(!(token && token.verifCode)) return '';
        return token.verifCode;
    }

    public getPhone(): string {
        const token = this.$decodedToken.value;
        if(!(token && token.phone)) return '';
        return token.phone;
    }

    public getExternalId(): string {
        const token = this.$decodedToken.value;
        if(!(token && token.externalId)) return '';
        return token.externalId;
    }

    public getToken() {
        return localStorage.getItem('accessToken');
    }

    public getExternalUser(): string {
        return localStorage.getItem('externalUser');
    }

    public getDecodedToken(): Observable<any> {
        return this.$decodedToken.asObservable()
    }

    public logout() {
        this.$decodedToken.next(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('customer');
        localStorage.removeItem('connectedBy');

    }

    public isLoggedIn() {
        return !!this.$decodedToken.value;
    }

    public setToken(token: string, connectedBy: string = null): Observable<boolean> {
        return this.decode(token).pipe(
            map((decoded: any) => {
                if (decoded) {
                    localStorage.setItem('accessToken', token);
                    localStorage.setItem('customer', decoded.sub);
                    if(connectedBy) localStorage.setItem('connectedBy', connectedBy)

                    this.$decodedToken.next(decoded);
                    return true;
                }
                return false;
            })
        );
    }

    public login(email, password, externalId, connectedBy = null): Observable<boolean> {
        const body = {
            email,
            password,
            externalId
        };

        return this.http.post(`${environment.baseUrl}${ApiUrl.LOGIN}`, body).pipe(
            mergeMap((response: any) => {
                if (response.accessToken) {
                    return this.setToken(response.accessToken, connectedBy);

                }
                
                return of(null);
            }),
            map(v => !!v),
            catchError(_ => of(false)),
        );
    }

    public loginWithToken(customerId, token): Observable<boolean> {

        return this.http.get(`${environment.baseUrl}${ApiUrl.LOGIN_WITH_TOKEN}`
            .replace(':customerId', customerId)
            .replace(':token', token)
            ).pipe(
            mergeMap((response: any) => {
                if (response.accessToken) 
                    return this.setToken(response.accessToken);
                
                return of(null);
            }),
            map(v => !!v),
            catchError(_ => of(false)),
        );
    }

    public verify(token:string): Observable<boolean> {
        return this.http.post<boolean>(`${environment.baseUrl}${ApiUrl.VERIFY_TOKEN}`, {token});
    }

    public decode(token:string): Observable<any> {
        return this.http.post(`${environment.baseUrl}${ApiUrl.DECODE_TOKEN}`, {token});
    }

    public register(firstname, lastname, email, phone, password, externalId): Observable<any> {

      const body = {
          firstname,
          lastname,
          email,
          phone,
          password,
          externalId
      };

      return this.http.post(`${environment.baseUrl}${ApiUrl.REGISTER}`, body).pipe(
        map((response: any) => {
            return this.customerAdapter.adapt(response);
        }),
        catchError(_ => of(false)),
      );

    }

    public resetData(firstname, lastname, email, phone, externalId, customerId): Observable<any> {

      const body = {
          firstname,
          lastname,
          email,
          phone,
          externalId
      };

      return this.http.put(`${environment.baseUrl}${ApiUrl.EDITREGISTER}`.replace(':id', customerId.toString()), body).pipe(

        map((response: any) => {
            return this.customerAdapter.adapt(response);
        }),
        catchError(_ => of(false)),
      );

    }

    public validPhone(customerId): Observable<boolean> {

        return this.http.patch<boolean>(`${environment.baseUrl}${ApiUrl.VALID_PHONE}`.replace(':id', customerId.toString()), {})
  
    }

    public editPassword(customerId: number, password: string, newPassword: string): Observable<boolean> {
        return this.http.patch<boolean>(`${environment.baseUrl}${ApiUrl.EDIT_PASSWORD}`.replace(':id', customerId.toString()), {password, newPassword}).pipe(
            map(response => !!response)
        )
    }

    public forgotPwd(email): Observable<boolean> {

        const body = {
            email
        };

        return this.http.post(`${environment.baseUrl}${ApiUrl.FORGOT_PWD}`, body).pipe(
            map((response: any) => {
                return true;
            }),
            catchError(_ => of(false)),
        );
    }

    public resetPwd(password: string, hash: string): Observable<boolean> {

        const body = {
            password,
            hash
        };
        return this.http.post(`${environment.baseUrl}${ApiUrl.RESET_PWD}`, body).pipe(
            map((response: any) => {
                return true;
            }),
            catchError(_ => of(false)),
        );
    }

    public newPwd(password: string, hash: string): Observable<boolean> {

        const body = {
            password,
            hash
        };
        return this.http.post(`${environment.baseUrl}${ApiUrl.NEW_PWD}`, body).pipe(
            map((response: any) => {
                return true;
            }),
            catchError(_ => of(false)),
        );
    }


    public resendCode(phone: string, id: number): Observable<any> {

      const body = {
        phone,
        id
      };

      return this.http.post(`${environment.baseUrl}${ApiUrl.NEW_CODE}`, body).pipe(
          map((response: any) => {
              return response;
          }),
          catchError(_ => of(false)),
      );
    }

    public hasCCFeatures(): Observable<boolean> {
        return this.$hasCCFeatures.asObservable();
    }

    public allowCC(value: boolean): void {
        this.$hasCCFeatures.next(value);
    }
}
