import { Injectable } from "@angular/core";
import { Version } from "../version.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class VersionAdapter implements Adapter<Version> {
    adapt(item: any): Version {
        return new Version(
            item.id,
            item.version,
            item.name,
            item.description
        );
    }
}