import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, finalize } from 'rxjs/operators';
import { PaymentService } from './api/payment.service';
import { from, Observable, of } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { OrderRequest } from '../models/requests/order.request';
import { LoaderService } from './loader.service';

declare var Stripe: any;

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    protected apiKey = environment.stripePublicKey;
  
    protected invoked = false;
    protected paymentHandler: any;

    constructor(
        protected loaderService: LoaderService,
        private _decimalPipe: DecimalPipe,
        protected paymentService: PaymentService
    ) {}

    /**
     * Invoquer Stripe
     * 
     * @returns {*} - payment handler
     */
    public invokeStripe(): Observable<any> {
        return from(new Promise( async (resolve, reject) => {
            try {
                if(this.invoked) return resolve(this.paymentHandler);

                if(!window.document.getElementById('stripe-script')) {
                    this.invoked = true;

                    const script = window.document.createElement("script");
                    script.id = "stripe-script";
                    script.type = "text/javascript";
                    script.src = "https://checkout.stripe.com/checkout.js";

                    script.onload = () => {
                        this.paymentHandler = (<any>window).StripeCheckout.configure({
                            key: this.apiKey,
                            locale: 'auto',
                            token: (stripeToken: any) => {
                                alert('Payment has been successfull!');
                            }
                        });

                        resolve(this.paymentHandler);
                    }

                    window.document.body.appendChild(script);
                }
            } catch(e) {
                reject(e)
            }
        }));
    }

    /**
     * Procéder au paiement
     * 
     * @param {string} hash - hash
     * @param {string} email - email de l'utilisateur
     * @param {number} totalCart - montant
     * @param {string} clientName - nom du client
     * @param {string} account - nom de l'account
     * 
     * @returns {*} - payment handler
     */
    public makePayment(hash: string, order: OrderRequest, clientName: string, account: string =  'acct_1JfMKpKE6SvgA2JI', cathErrorFn: any = (e) => of(null)) {
        return from(new Promise( async (resolve, reject) => {
            try {  
                const rid = `${Math.random()}`;

                this.paymentHandler = (<any>window).StripeCheckout.configure({
                    key: this.apiKey,
                    locale: 'auto',
                    token: (stripeToken: any) => {
                        this.loaderService.add(rid);

                        this.paymentService.payOrder(hash, order, stripeToken.id, account).pipe(
                            catchError(cathErrorFn),
                            finalize(() => this.loaderService.remove(rid))
                        ).subscribe(e => {
                            resolve(e)
                        });
                    }
                });
                this.paymentHandler.open({
                    name: 'ACCESSMENU',
                    description: `${clientName} : ${this._decimalPipe.transform(order.total, '1.2-2')}${order.currency}`,
                    currency: order.currency,
                    locale: 'fr',
                    email: order.email,
                    on_behalf_of: account,
                    destination: account
                });

            } catch(e) {
                reject(e)
            }
        }))
    }
}
