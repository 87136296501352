import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateService {

    protected datePipe: DatePipe;
  
    // contructor
    constructor() {
        this.datePipe = new DatePipe('fr-FR');
    }

    /**
     * Renvoie la date formatée
     * 
     * @param {Date} date 
     * 
     * @returns {string} - date formatée 
     */
    public convertDateToString(date: Date): string {
      return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    /**
     * Créer une date selon les paramètres
     * 
     * @param {number} y 
     * @param {number} m 
     * @param {number} d 
     * @param {number} h 
     * @param {number} m2 
     * 
     * @returns {Date}
     */
    create = (y: number, m: number, d: number, h: number = 0, m2: number = 0): Date => {
        const date = new Date();
        date.setFullYear(y)
        date.setMonth(m - 1)
        date.setDate(d)
        date.setHours(h)
        date.setMinutes(m2)
        date.setSeconds(0)
        date.setMilliseconds(0)
        return date
    }

    /**
     * Converti à partir d'un string
     * 
     * @param {string} str 
     * 
     * @returns {Date} 
     */
    fromString = (str: string): Date => {
        return moment(str).toDate()
    }

    /**
     * Insere le temps dans une date et renvoie une nouvelle date
     * 
     * @param {string} time 
     * @param {Date} date 
     * 
     * @returns {Date} 
     */
    setTime = (time: string, date: Date): Date => {
        const newDate = new Date(date);
        const m = time.match(/^([0-9]+):([0-9]+)/i);
        newDate.setHours(parseInt(m[1]));
        newDate.setMinutes(parseInt(m[2]))
        newDate.setSeconds(0);
        newDate.setMilliseconds(0)
        return newDate
    }

    /**
     * Transforme une date Iso en date local
     * 
     * @param {string} dateStr 
     * 
     * @returns {string}
     */
    toLocal = (dateStr: string): string => {
        return dateStr.replace(/^((?:[0-9]+?-){2}[0-9]+?)T.*?$/, '$1')
    }

    /**
     * Renvoie un objet Date avec les info de date
     * 
     * @param {Date} date 
     * 
     * @returns {object}
     */
    getFullDateObject(date: Date = new Date) {
        return {
            origin: date, 
            isoString:  date.toISOString(), 
            dateString: date.toDateString(), 
            localDateString: date.toLocaleDateString(),
            date: date.getDate(),
            fullYear: date.getFullYear(),
            month: date.getMonth(),
            day: date.getDay(),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
            milliseconds: date.getMilliseconds(),
            utc: {
                date: date.getUTCDate(),
                fullYear: date.getUTCFullYear(),
                month: date.getUTCMonth(),
                day: date.getUTCDay(),
                hours: date.getUTCHours(),
                minutes: date.getUTCMinutes(),
                seconds: date.getUTCSeconds(),
                milliseconds: date.getUTCMilliseconds(),
            }
        }
    }
}
