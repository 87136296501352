import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable({
    providedIn: 'root'
})
export class AddLoaderInterceptor implements HttpInterceptor {
  private activeLoader = false;

  constructor(
    private loaderService: LoaderService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if(!this.activeLoader) return next.handle(req)
      const rid = `${Math.random()}'_'${req.url}`;

      if(!req.url.match(/\/has18\-/)) this.loaderService.add(rid);
      return next.handle(req).pipe(finalize(() => setTimeout(() => this.loaderService.remove(rid), 300)));        
  }
}
