import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppStateService {

    protected $addToCart: BehaviorSubject<boolean>
    protected $inOrder: BehaviorSubject<boolean>;
    protected minOrder: boolean = false;
    protected minOrderValue: number = 0;
    protected $scroll: BehaviorSubject<number>;

    public isScrolling: boolean = false;
    public redirectAfterLoginOnOrder: boolean = false;

    // contructor
    constructor() {
        this.$inOrder = new BehaviorSubject<any>(false);
        this.$addToCart = new BehaviorSubject<any>(false);
        this.$scroll = new BehaviorSubject<number>(0);
    }

    /**
     * Change le mode Ajout au panier
     * 
     * @param {boolean} value 
     */
    setAddToCart(value: boolean) {
      this.$addToCart.next(value);
    }
  
    /**
     * Change le mode InOrder
     * 
     * @param {boolean} order 
     */
    setInOrder(order: boolean) {
      this.$inOrder.next(order);
    }
  
    /**
     * Change minOrder
     * 
     * @param {boolean} value 
     */  
    setMinOrder(value: boolean) {
      this.minOrder = value;
    }

    /**
     * Change la valeur de minOrder
     * 
     * @param {number} value 
     */
    setMinOrderValue(value: number) {
      this.minOrderValue = value;
    }

    /**
     * Récupère l'observable du mode AddToCart
     * 
     * @returns {Observable.<boolean>} - Renvoie l'observable du mode d'ajout au panier (V/F)
     */
    getAddToCart(): Observable<boolean> {
      return this.$addToCart.asObservable();
    }

    /**
     * Récupère l'observable du mode InOrder
     * 
     * @returns {Observable.<boolean>} - Renvoie l'observable
     */
    getInOrder(): Observable<boolean> {
      return this.$inOrder.asObservable();
    }

    /**
     * Récupère le mode minOrder
     * 
     * @returns {boolean} - Renvoie le mode minOrder
     */
    getMinOrder(): boolean {
      return this.minOrder;
    }

    /**
     * Récupère la valeur de minOrder
     * 
     * @returns {number} - Renvoie la valeur du minOrder
     */
    getMinOrderValue(): number {
      return this.minOrderValue;
    }

   
    setScrollTo(value: number): void {
      return this.$scroll.next(value);
    }

    getScrollTo(): Observable<number> {
      return this.$scroll.asObservable();
    }
}
