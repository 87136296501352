export class Customer {

    id: number;
    lastname: string;
    firstname: string;
    email: string;
    phone: string;
    externalId: string;
    verifCode: string;

    constructor(id: number, lastname: string, firstname: string, email: string, phone: string, externalId: string, verifCode: string) {
        this.id = id;
        this.lastname = lastname;
        this.firstname = firstname;
        this.email = email;
        this.phone = phone;
        this.externalId = externalId;
        this.verifCode = verifCode
    }
}
