import { Injectable } from "@angular/core";
import { ClientIllustration } from "../client-illustration.model";
import { Client } from "../client.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class ClientAdapter implements Adapter<Client> {
    adapt(item: any): Client {
        return new Client(
            item.id,
            item.name,
            item.websiteUrl,
            item.reservationUrl,
            item.facebookUrl,
            item.twitterUrl,
            item.instagramUrl,
            item.youtubeUrl,
            item.tripadvisorUrl,
            item.whatsAppLink,
            item.hours,
            item.phone,
            item.address,
            item.city,
            item.zipcode,
            item.country,
            item.qrcodes,
            item.color1,
            item.color2,
            item.color3,
            item.color4,
            item.color5,
            item.color6,
            item.color7,
            item.color8,
            item.theme,
            item.languageId,
            item.logoUrl,
            item.isEnable,
            item.createdAt
        );
    }

    adaptIllustration(item: any): ClientIllustration {
        return new ClientIllustration(
            item.id,
            item.url
        );
    }
}