import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { VersionService } from '../services/api/version.service';
import { RouterService } from '../services/router.service';
import { QrCodeService } from '../services/api/qrcode.service';

@Injectable({
    providedIn: 'root'
})
export class VersionGuard implements CanActivate {

    public version: string;

    constructor(
        protected qrcodeService: QrCodeService,
        protected versionService: VersionService,
        protected routerService: RouterService,
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const hash = next.params.hash || next.parent.params.hash;
        const to =  next.data.to || next.parent.data.to;

        return this.versionService.get(hash).pipe(
            map(version => {
                if(version) {
                    const redirectUri = version.name === "v2" ? ['/', version.name, 'qrcode', hash] : ['/', version.name, hash];
                    if(to) redirectUri.push(to);
                    else if(version.name === "v2") redirectUri.push('list');
                    this.routerService.navigate(redirectUri)
                }
                return true;
            })
        ) 
    
    }
}