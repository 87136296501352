import { MenuPrice } from "./menu-price.model";
import { MenuStep } from "./menu-step.model";

export class Menu {

    id: number;
    position: number;
    name: string;
    price: string;
    isMultiprice: boolean;
    isEnable: boolean;
    scheduling: string;
    isScheduled: boolean;
    availability: boolean;
    pictureUrl: string;
    famillyId: number;
    steps: MenuStep[];
    prices: MenuPrice[];

    constructor(id: number, position: number, name: string, price: string, isMultiprice: boolean, isEnable: boolean, scheduling: string, isScheduled: boolean, availability: boolean, pictureUrl: string,
        famillyId: number, steps: MenuStep[], prices: MenuPrice[]) {
        this.id = id;
        this.position = position;
        this.name = name;
        this.price = price;
        this.isMultiprice = isMultiprice;
        this.isEnable = isEnable;
        this.scheduling = scheduling;
        this.isScheduled = isScheduled;
        this.availability = availability;
        this.pictureUrl = pictureUrl;
        this.famillyId = famillyId;
        this.steps = steps;
        this.prices = prices;
    }
}