import { Injectable } from "@angular/core";
import { QrcodeTheme } from "../qrcode-theme.model";
import { Adapter } from "./adapter";

@Injectable({
    providedIn: "root",
})
export class QrcodeThemeAdapter implements Adapter<QrcodeTheme> {

    constructor() {}

    adapt(item: any): QrcodeTheme {
        
        return new QrcodeTheme(
            item.id,
            item.colors,
            item.versionId
        );
       
    }
}