import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiUrl } from './api.url';
import { OrderRequest } from '../../models/requests/order.request';

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        protected http: HttpClient
    ) {}

    public payOrder(hash: string, order: OrderRequest, token: string, onbehalof: string): Observable<any> {
      order.token = token;
      order.onbehalof = onbehalof;

      return this.http.post(`${environment.baseUrl}${ApiUrl.PAY_ORDER}`.replace(':hash', hash), order).pipe(
        map((response: any) => {
           return response;
        })
      );
    }
}
