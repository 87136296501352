import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

export enum OverlayState {
  close = "close",
  open = "open"
}

@Injectable({
  providedIn: "root"
})
export class OverlayService {

  protected $data: BehaviorSubject<{component: any, data: {[key: string]: any}}>;
  protected $state: BehaviorSubject<OverlayState>;
  protected $closeData: BehaviorSubject<OverlayState>;

  constructor() {
    this.$data =  new BehaviorSubject<{component: any, data: {[key: string]: any}}>(null);
    this.$closeData =  new BehaviorSubject<any>(null);
    this.$state =  new BehaviorSubject<any>(OverlayState.close);
  }

  open(component: any, data: {[key: string]: any} = {})  {
    this.$data.next({component, data});
    this.$closeData.next(null);
    this.$state.next(OverlayState.open);
  }

  getData(): Observable<{component: any, data: {[key: string]: any}}> {
    return this.$data.asObservable();
  }

  getState(): Observable<any> {
    return this.$state.asObservable();
  }

  getDataAfterClose(): Observable<any> {
    return this.$closeData.asObservable();
  }

  close(data = null) {
    this.$data.next(null);
    this.$closeData.next(data);
    this.$state.next(OverlayState.close);
  }
}
