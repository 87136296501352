import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FamilyAdapter } from '../../models/adapter/family.adapter';
import { Family } from '../../models/family.model';
import { ApiUrl } from './api.url';

@Injectable({
    providedIn: 'root'
})
export class FamillyService {

    constructor(
        protected http: HttpClient,
        protected familyAdapter: FamilyAdapter
    ) {}

    public getFamilies(hash: string, onlyAvailable: boolean = false, date: string = null): Observable<Family[]> {

        const uri = date ? `${environment.baseUrl}${ApiUrl.GET_FAMILIES_BY_DATE}`.replace(':date', date) : `${environment.baseUrl}${ApiUrl.GET_FAMILIES}`;

        return this.http.get(uri.replace(':hash', hash)).pipe(
            map((response: any) => {
                const results =  response.map(item => this.familyAdapter.adapt(item));

                if(onlyAvailable) {
                    return results.filter(r => r.availability).map(r => {
                        r.menus = r.menus.filter(m => m.availability);
                        r.products = r.products.filter(p => p.availability);

                        return r;

                    }).filter(r => r.menus.length > 0 || r.products.length > 0)
                }

                return results;
            })
        );
    }

    public getClickCollectFamilies(hash: string, onlyAvailable: boolean = false, date: string = null): Observable<Family[]> {

        const uri = date ? `${environment.baseUrl}${ApiUrl.GET_CLICK_COLLECT_FAMILIES_BY_DATE}`.replace(':date', date) : `${environment.baseUrl}${ApiUrl.GET_CLICK_COLLECT_FAMILIES}`;

        return this.http.get(uri.replace(':hash', hash)).pipe(
            map((response: any) => {
                const results =  response.map(item => this.familyAdapter.adapt(item));
                
                if(onlyAvailable) {
                    return results.filter(r => r.availability).map(r => {
                        r.menus = r.menus.filter(m => m.availability);
                        r.products = r.products.filter(p => p.availability);
                        
                        return r;

                    }).filter(r => r.products.length > 0 || r.menus.length > 0)
                }

                return results;
           })
        );
    }

    public getFamilly(hash: string, familyId: number, onlyAvailable: boolean = false, date: string = null): Observable<Family> {

        const uri = date ? `${environment.baseUrl}${ApiUrl.GET_FAMILY_BY_DATE}`.replace(':date', date) : `${environment.baseUrl}${ApiUrl.GET_FAMILY}`;

        return this.http.get(uri.replace(':hash', hash).replace(':id', familyId.toString())).pipe(
            map((response: any) => {
                if(!response) return null;
                
                const result =  this.familyAdapter.adapt(response);

                if(onlyAvailable) {
                    if(!result.availability) return null;

                    result.menus = result.menus.filter(m => m.availability);
                    result.products = result.products.filter(p => p.availability);

                    return result.products.length > 0 || result.menus.length > 0 ? result : null; 
                }

                return result;
            })
        );
    }

    public getClickCollectFamily(hash: string, familyId: number, onlyAvailable: boolean = false, date: string = null): Observable<Family> {

        const uri = date ? `${environment.baseUrl}${ApiUrl.GET_CLICK_COLLECT_FAMILY_BY_DATE}`.replace(':date', date) : `${environment.baseUrl}${ApiUrl.GET_CLICK_COLLECT_FAMILY}`;

        return this.http.get(uri.replace(':hash', hash).replace(':id', familyId.toString())).pipe(
            map((response: any) => {
              if(!response) return null;

              const result =  this.familyAdapter.adapt(response);
  
              if(onlyAvailable) {
                  if(!result.availability) return null;
  
                  result.menus = result.menus.filter(m => m.availability);
                  result.products = result.products.filter(p => p.availability);

                  return result.products.length > 0 || result.menus.length > 0 ? result : null; 
                }
  
              return result;
            }),
        );
    }

    public has18YearOld(hash: string, ids: number[]): Observable<boolean> {
        return this.http.post<boolean>(`${environment.baseUrl}${ApiUrl.HAS18_FAMILIES}`.replace(':hash', hash), {ids})
    }
}
