import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    protected $loading: BehaviorSubject<boolean>
    protected lists: {id: string, loading: boolean}[] = [];

    // contructor
    constructor() {
        this.$loading = new BehaviorSubject<boolean>(false);
    }

    add(id: string): void {
        const l = this.lists.find(l => l.id === id);

        if(l) l.loading = true;
        else this.lists = [...this.lists, {id, loading: true}];

        this.$loading.next(true);
    }

    remove(id: string): void {
        const l = this.lists.find(l => l.id === id);

        if(l) {
            l.loading = false
        }
        
        if(this.lists.every(v => {
            return !v.loading;
        })) this.$loading.next(false);
    }

    getLoading(): Observable<boolean> {
       return this.$loading.asObservable();
    }

}
